<!--企业身份-选择个人身份（展示企业架构和外部联系人）-->
<template>
  <div v-if="show" class="contacts-outer">
    <div>
      <Header title="选择联系人" @onClose="onHeaderClose" />
      <van-cell-group>
        <van-cell title="北京人人信金融科技有限公司" @click="handleGoCom">
          <template #icon>
            <van-icon
              :name="
                userInfo.comHeadImg || require('@/assets/imgs/company.svg')
              "
              class="search-icon"
            />
          </template>
        </van-cell>
        <van-cell
          v-if="!hiddenOuterList"
          title="外部联系人"
          @click="handleGoPer"
        >
          <template #icon>
            <van-icon
              :name="require('@/assets/imgs/default_img.png')"
              class="search-icon"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <CompanyFriend
      append-to-body
      :show="comShow"
      @onClose="onClose"
      @onConfirmClose="onConfirmClose"
      @roleCallback="onComCallback"
    />
    <PersonalFriend
      v-if="!hiddenOuterList"
      append-to-body
      :show="perShow"
      @onClose="onClose"
      @onConfirmClose="onConfirmClose"
      @roleCallback="onRoleCallback"
      :type="1"
    />
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { hasAuth } from '@/utils/hasAuth'
  import CompanyFriend from './companyFriend.vue'
  import Header from './components/header.vue'
  import PersonalFriend from './personalFriend.vue'

  export default {
    props: {
      show: {
        type: Boolean,
        default: () => false,
      },
    },
    components: {
      CompanyFriend,
      PersonalFriend,
      Header,
    },
    data() {
      return {
        selectText: '',
        radio: '',
        comShow: false,
        perShow: false,
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      /**
       * 只展示企业架构
       * 1. 选择抄送人
       * 2. 无外部联系人权限
       */
      hiddenOuterList() {
        return !hasAuth(['addressBook_externalContacts'])
      },
    },
    methods: {
      // 搜索
      handleGoCom() {
        this.comShow = true
      },
      handleGoPer() {
        this.perShow = true
      },
      onClose() {
        this.comShow = false
        this.perShow = false
      },
      onConfirmClose() {
        this.onClose()
        this.onHeaderClose()
      },
      onHeaderClose() {
        this.$emit('onClose')
      },
      onRoleCallback(val) {
        this.$emit('roleCallback', val)
      },
      onComCallback(dep, val) {
        this.$emit('comCallback', dep, val)
      },
    },
  }
</script>
<style lang="less" scoped>
  .cur-text {
    font-size: 12px;
    font-weight: 400;
    color: #8a9ab4;
  }
  .cur-padding {
    padding: 0 16px;
  }
  .contacts-outer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 999999;
    overflow-y: scroll;
    overflow-x: hidden;
    .van-cell {
      height: 66px;
      display: flex;
      align-items: center;
    }
    .van-icon {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      display: block;
      img {
        width: 100%;
        height: 100%;
        border-radius: 18px;
      }
    }
    .van-cell__title {
      font-size: 14px;
      color: #111a34;
      line-height: 36px;
      margin-left: 12px;
    }
    .search-icon {
      img {
        width: 36px;
        height: 36px;
        object-fit: cover;
      }
    }
  }
</style>
