<template>
  <div class="partner-component">
    <component
      :is="curComponent"
      :partner-model="partnerModel"
      :index="index"
    />
  </div>
</template>

<script>
  import PartnerLauncher from './PartnerLauncher.vue'
  import PartnerPersonal from './PartnerPersonal.vue'
  import PartnerCom from './PartnerCom.vue'

  export default {
    name: 'Partner',
    components: {
      // PartnerCom,
      // PartnerLauncher,
      // PartnerPersonal,
    },
    props: {
      partnerModel: {
        type: Object,
        default: () => {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      curComponent() {
        switch (this.partnerRole) {
          case 0:
            return PartnerLauncher
          case 1:
            return PartnerPersonal
          case 2:
            return PartnerCom
          default:
            throw new Error('不存在的参与方类型')
        }
      },

      partnerRole() {
        // console.log(this.partnerModel.partnerRole)
        return this.partnerModel.partnerRole
      },
    },
  }
</script>

<style lang="less" scoped></style>
