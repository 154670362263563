<template>
  <div class="select-file">
    <div :class="open ? 'file-list' : 'file-list file-list-open'">
      <div v-if="progressFile.length" style="width:100%;position: relative;">
        <div
          class="file-list-item"
          v-for="(file, index) in progressFile"
          :key="file.fileKey"
        >
          <div class="file-container">
            <img
              :src="
                file.format &&
                  require(`@/assets/imgs/signFile/${file.format}.svg`)
              "
              alt="cover"
            />
            <div class="file-text">
              <span class="file-name">{{ file.fileName }}</span>
              <span class="file-size">{{ sizeTostr(file.fileSize) }}</span>
            </div>
          </div>
          <div class="progress-container">
            <template v-if="file.filePercentage === 100">
              <van-icon name="checked" size="12" color="#00b973" />
              <span class="success-text">上传成功</span>
            </template>
            <FileProgress v-else />
          </div>
          <img
            class="delete-icon"
            src="@/assets/imgs/编组 17@2x.png"
            @click.stop="handleDeleteProgressFile(file, index)"
          />
          <!-- <van-loading class="empty-loading" /> -->
        </div>
      </div>
      <div
        class="file-list-item"
        v-for="(file, index) in fileList"
        :key="file.fileKey"
        @click="handlePreview(file)"
      >
        <div class="file-container">
          <img
            :src="
              file.format &&
                require(`@/assets/imgs/signFile/${file.format}.svg`)
            "
            alt="cover"
            @click="handlePreviewCurFile(index)"
          />
          <div class="file-text">
            <span class="file-name">{{ file.fileName }}</span>
            <span class="file-size">{{ sizeTostr(file.fileSize) }}</span>
          </div>
        </div>
        <div class="progress-container">
          <van-icon name="checked" size="12" color="#00b973" />
          <span class="success-text">上传成功</span>
        </div>
        <img
          class="delete-icon"
          src="@/assets/imgs/编组 17@2x.png"
          @click.stop="handleDeleteCurfile(index)"
        />
      </div>
    </div>
    <p class="lookMore" v-if="fileList.length > 2">
      <span v-if="open" @click="handleSwitch" class="open">
        展开剩余{{ fileList.length - 2 }}个文件
      </span>
      <span v-else @click="handleSwitch" class="stop">
        收起
      </span>
    </p>

    <!-- upload input -->
    <input
      type="file"
      class="upload-file-input"
      ref="uploadInput"
      @change="onUploadInputChange"
    />
    <!-- upload input -->

    <FilePreview append-to-body v-model="showPreview" :file="file" />
  </div>
</template>

<script>
  import {
    uploadFile,
    translateFileToBase64,
    getFileData,
  } from '@/model/launch'
  import { sizeTostr } from '@/utils'
  import { isFileType } from '@/utils/helper'
  import { mapState, mapGetters } from 'vuex'
  import { Progress, Icon } from 'vant'
  import FilePreview from './FilePreview.vue'
  import FileProgress from './FileProgress.vue'

  export default {
    name: 'SelectFile',
    components: {
      FilePreview,
      FileProgress,
    },
    props: {
      fileList: {
        type: Array,
        default: () => [],
      },
      value: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        // 当前要预览的文件的 index
        curPreviewFileIndex: 0,
        showPreview: false,
        file: {},
        open: true,
        fileTypeImg: [
          'ppt',
          'pdf',
          'doc',
          'docx',
          'jpeg',
          'jpg',
          'png',
          'excel',
        ],
        progressList: [],
      }
    },
    created() {
      this.init()
      console.log('progressFile: ', this.progressFile)
    },
    watch: {
      value(newValue) {
        this.value = newValue
        this.$emit('input', newValue)
      },
      progressFile: {
        handler(newValue) {
          this.setFileType(this.progressFile)
          this.progressList = newValue
          this.setFileType(this.fileList)
        },
        deep: true,
      },
    },
    computed: {
      sizeTostr() {
        return sizeTostr
      },
      ...mapState({
        progressFile: state => state.launch.progressFile,
      }),
    },
    methods: {
      init() {
        // console.log('progressFile: ',this.progressFile);
        this.setFileType(this.progressFile)
        this.setFileType(this.fileList)
      },
      setFileType(file) {
        file.forEach(item => {
          const objectFormat = item.fileName.substr(
            item.fileName.lastIndexOf('.') + 1
          )
          for (let i = 0; i < this.fileTypeImg.length; i++) {
            if (objectFormat === this.fileTypeImg[i]) {
              item.format = this.fileTypeImg[i]
              break
            } else {
              item.format = '未知'
            }
          }
        })
        this.$forceUpdate()
      },
      handleClickUploadButton() {
        const { uploadInput } = this.$refs
        uploadInput.click()
      },
      async onUploadInputChange(e) {
        const { uploadInput } = this.$refs
        let loadingInstance

        try {
          loadingInstance = this.$toast.loading({
            message: '上传中',
            forbidClick: false,
            duration: 20000,
          })
          const file = e.target.files[0]
          if (file) {
            const { name: fileName } = file
            const { objectFormat, objectBase64 } = await getFileData(file)
            const { fileKey, fileUrl, imgList } = await uploadFile({
              objectBase64,
              objectFormat,
            })
            const fileInfo = {
              fileIndex: this.fileList.length,
              fileName,
              fileKey,
              fileUrl,
              imgList,
              pageNum: imgList.length,
            }
            uploadInput.value = ''
            this.$emit('onUploadFile', fileInfo)
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.$toast.clear()
        }
      },
      handlePreviewCurFile(index) {
        this.curPreviewFileIndex = index
      },
      handleDeleteCurfile(index) {
        this.$emit('onDeleteFile', index)
      },
      handleDeleteProgressFile(file, index) {
        // console.log('file: ',file.cancel());
        file.cancel()
        this.progressFile.splice(index, 1)
      },
      handlePreview(file) {
        this.showPreview = true
        this.file = file
      },
      handleSwitch() {
        this.open = !this.open
      },
    },
  }
</script>
<style lang="less">
  .select-file {
    .van-progress__pivot {
      display: none;
    }
  }
</style>
<style scoped lang="less">
  .select-file {
    position: relative;
    .file-list {
      width: 100%;
      // min-height: 64px;
      max-height: 180px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .upload-button {
        color: #969799;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 91px;
        border: 1px dashed #e2e4ea;
        .icon {
          font-size: 18px;
        }
        .text {
          margin-top: 10px;
        }
      }
      .file-list-item {
        width: 100%;
        height: 78px;
        background: #f8f9fa;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        position: relative;
        margin-top: 12px;
        .file-container {
          height: 56px;
          padding: 0 12px;
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 36px;
          }
        }
        .progress-container {
          padding: 0 12px;
          height: 20px;
          background: #fff;
          font-size: 10px;
          display: flex;
          align-items: center;
          .success-text {
            color: #00b973;
            margin-left: 4px;
          }
          .err-text {
            color: #f93a4a;
            margin-left: 4px;
          }
        }
        .file-text {
          display: flex;
          flex-direction: column;
          align-content: center;
          margin-left: 12px;
          .file-name {
            max-width: 168px;
            color: #1f2023;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .file-size {
            color: #747980;
            font-size: 11px;
            line-height: 20px;
          }
        }
        .delete-icon {
          width: 18px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .file-list-open {
      height: auto;
      overflow: visible;
      max-height: initial;
    }
    .lookMore {
      width: 100%;
      text-align: right;
      padding: 0 16px;
      margin-top: 6px;
      .open {
        font-size: 12px;
        color: #111a34;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 4px;
          right: -12px;
          border-left: 4px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #434751;
        }
      }
      .stop {
        font-size: 12px;
        color: #111a34;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 4px;
          left: 28px;
          border-left: 4px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 6px solid #434751;
        }
      }
    }
    .van-loading {
      position: absolute;
      top: 20px;
      right: 30px;
    }
    .empty-loading {
      top: 19px;
    }
  }
  .upload-file-input {
    display: none;
  }
</style>
