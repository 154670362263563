<template>
  <div class="simple-launch-page" @click="handleAllClose">
    <template v-show="!fileUploadLoading">
      <div class="launch-item-container">
        <div class="launch-item">
          <div class="item-title">
            <span>第1步 签署文件</span>
            <div
              v-if="simpleLaunchModel.fileList.length < 10"
              @click="handleClickUploadButton"
              class="right"
            >
              <img src="@/assets/imgs/add-icon.png" alt="" />
              <span>添加文件</span>
            </div>
          </div>
          <select-file
            :file-list="simpleLaunchModel.fileList"
            @onDeleteFile="onDeleteFile"
            v-model="fileListloading"
          />
        </div>
      </div>
      <div class="launch-item-container">
        <div class="launch-item">
          <div class="item-title">第2步 签署规则</div>
          <div class="rule-list">
            <div class="rule-item">
              <div class="rule-name">标题</div>
              <div class="rule-value">
                <input
                  class="title-input"
                  placeholder="请输入签署标题"
                  v-model="simpleLaunchModel.titleName"
                  type="text"
                  maxlength="20"
                />
              </div>
            </div>
            <div class="rule-item">
              <div class="rule-name">签署有效期（天）</div>
              <div class="rule-value">
                <div class="valid-day">
                  <div class="valid-day-value">
                    <span
                      class="operation-icon decrement"
                      @click="handleDecrement"
                    >
                      <img src="@/assets/imgs/signFile/形状.svg" alt="" />
                    </span>
                    <van-field
                      class="operation-value"
                      v-model.trim.number="simpleLaunchModel.validDay"
                      type="number"
                      input-align="center"
                      @input="handleValiDay"
                    />
                    <span
                      class="operation-icon increment"
                      @click="handleIncrement"
                    >
                      <img src="@/assets/imgs/signFile/加.svg" alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="rule-item">
              <div class="rule-name">必须实名签署</div>
              <div class="rule-value">
                <van-checkbox
                  class="switch"
                  v-model="isCreditSign"
                  @change="onCreditSignChange"
                ></van-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="launch-item launch-item-three">
          <div class="item-title">
            <span>第3步 签署流程</span>
            <div class="right">
              <img
                @click.stop="handleShowTip"
                class="tip"
                src="@/assets/imgs/tip.png"
                alt=""
              />
              <span class="order" @click="handleShowOrderBubble">{{
                showSignOrder
              }}</span>
              <div v-if="showTip" class="tip-content">
                【无序签】对签署顺序没有要求，多方可以同时签署
                【顺序签】希望指定签署前后顺序，A->B->C
              </div>
              <div
                v-if="showSignOrderBubble"
                :class="
                  !signOrder
                    ? 'bubble-popup bubble-popup-before'
                    : 'bubble-popup'
                "
              >
                <p
                  :class="!signOrder ? 'order-active' : ''"
                  @click="handleSelectOrder(0)"
                >
                  无序签
                </p>
                <p
                  :class="signOrder ? 'order-active' : ''"
                  @click="handleSelectOrder(1)"
                >
                  顺序签
                </p>
              </div>
            </div>
          </div>
          <div class="partner-list">
            <Draggable
              v-model="simpleLaunchModel.partnerList"
              @start="onStart"
              @end="onEnd"
              animation="300"
              :delay="100"
            >
              <partner
                v-for="(partner, index) in simpleLaunchModel.partnerList"
                :key="partner.partnerIndex"
                :partner-model="partner"
                :index="index"
              />
            </Draggable>
          </div>
          <div
            v-if="simpleLaunchModel.partnerList.length < 20"
            class="partner-button-list"
          >
            <p>添加签署参与方</p>
            <div class="button-box">
              <van-button
                class="partner-button"
                round
                plain
                type="primary"
                @click="createPartnerLauncher"
              >
                <van-icon name="plus" class="add-icon" />
                发起方</van-button
              >
              <van-button
                class="partner-button"
                round
                plain
                type="primary"
                @click="createPartnerCom"
                ><van-icon name="plus" class="add-icon" /> 外部企业</van-button
              >
              <van-button
                class="partner-button"
                round
                plain
                type="primary"
                @click="createPartnerPersonal"
                ><van-icon name="plus" class="add-icon" /> 外部个人</van-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="page-footer">
        <div class="draft-button" @click="handleSaveDraft">
          <img src="@/assets/imgs/编组 3@2x.png" alt="" />
          <span>草稿</span>
        </div>
        <div>
          <!-- <van-button class="footer-button" type="info" round
            >指定签署位置</van-button
          > -->
          <van-button
            class="footer-button"
            type="primary"
            round
            @click="handleSave"
            >直接发起</van-button
          >
        </div>
      </div>
    </template>
    <div class="loadingcContent" v-if="fileUploadLoading">
      <div class="loadingProgress">
        <van-loading type="spinner" />
        文件加载中...95%
      </div>
    </div>
    <van-dialog
      class="warn-dialog"
      append-to-body
      :show-confirm-button="false"
      v-model="showWarnDialog"
    >
      <div class="main">
        <div class="title">风险提示</div>
        <div class="content">
          如果签署方未实名签署，在文件发生纠纷时，需要您提供该签署方身份认知的证据，如需避免风险，请不要关闭使实名要求。
        </div>
      </div>
      <div class="footer">
        <van-button
          class="warn-dialog-footer-button button-cancel"
          @click="handleSetChecked"
          >取消</van-button
        >
        <van-button
          disabled
          class="warn-dialog-footer-button button-waiting"
          v-show="!isWarnDialogConfirmButtonEnabled"
          >确认关闭(<van-count-down
            class="button-text"
            :time="time"
            format="sss"
            ref="countDown"
            :auto-start="false"
            @finish="onCountDownFinish"
          />)</van-button
        >
        <van-button
          v-show="isWarnDialogConfirmButtonEnabled"
          class="warn-dialog-footer-button button-confirm"
          @click="onWarnDialogConfirm"
          >确认关闭</van-button
        >
      </div>
    </van-dialog>
    <!-- upload input -->
    <input
      type="file"
      class="upload-file-input"
      ref="uploadInput"
      @change="onUploadInputChange"
    />
    <!-- upload input -->
  </div>
</template>

<script>
  import cloneDeep from 'lodash.clonedeep'
  import { generateRandomKey } from '@/utils/helper'
  import { mapState, mapGetters } from 'vuex'
  import contractBusinessApi from '@/api/contractBusiness'
  import { hasAuth } from '@/utils/hasAuth'
  import {
    uploadFile,
    translateFileToBase64,
    getFileData,
  } from '@/model/launch'
  import Draggable from 'vuedraggable'
  import { SAVE_DRAFT_STEP } from '@/common/signEnum'
  import SelectFile from './components/SelectFile.vue'
  // import PartnerLauncher from './components/PartnerLauncher.vue'
  // import PartnerPersonal from './components/PartnerPersonal.vue'
  // import PartnerCom from './components/PartnerCom.vue'
  import Partner from './components/Partner.vue'

  export default {
    name: 'SimpleLaunch',
    components: { SelectFile, Partner, Draggable },
    provide() {
      return {
        onPartnerUpdate: this.onPartnerUpdate,
        onPartnerDelete: this.onPartnerDelete,
      }
    },
    data() {
      // 发起方
      this.partnerLauncherModel = {
        partnerIndex: undefined,
        defaultName: undefined,
        // 是否指定盖章人，如果不指定则为 undefined 如果指定了 则要存储uid
        uid: undefined,
        // 指定章的id
        assignId: undefined,
        // 指定章
        assignType: 1,
        partnerRole: 0,

        // 个人身份
        handlerTel: undefined,
        handlerName: undefined,
      }
      // 外部个人
      this.partnerPersonalModel = {
        partnerIndex: undefined,
        defaultName: undefined,
        // 经办人名称
        handlerName: undefined,
        // 经办人手机号/邮箱
        handlerTel: undefined,
        // 指定章
        // assignType: 1,
        partnerRole: 1,
      }
      // 外部企业
      this.partnerComModel = {
        partnerIndex: undefined,
        defaultName: undefined,
        comId: undefined,
        // 企业名称
        comName: undefined,
        // 经办人名称
        handlerName: undefined,
        // 经办人手机号/邮箱
        handlerTel: undefined,
        // 指定章
        assignType: 1,
        partnerRole: 2,
      }
      return {
        key: undefined,
        // 是否为实名签署
        isCreditSign: true,
        // 是否展示警告弹窗
        showWarnDialog: false,
        // 警告弹窗确认按钮是否可用
        isWarnDialogConfirmButtonEnabled: false,
        time: 3 * 1000,
        simpleLaunchModel: {
          titleName: undefined,
          businessId: undefined,
          fileList: [],
          launchSign: 0,
          masterId: undefined,
          noCreditSign: 0,
          partnerList: [],
          validDay: 30,
        },
        drag: false,
        showTip: false,
        showSignOrderBubble: false,
        // 签署顺序
        signOrder: 0,
        signOrderNumFlag: 0,
        fileListloading: false,
        fileUploadLoading: false,
      }
    },
    watch: {
      simpleLaunchModel: {
        handler(newValue) {
          this.$nextTick(() => {
            // this.$store.commit('launch/setSimpleLaunchModel', {
            //   [this.key]: newValue,
            // })
            this.storeData(newValue)
          })
        },
        deep: true,
      },
      isCreditSign: {
        handler(newValue) {
          if (newValue) {
            this.simpleLaunchModel.noCreditSign = 0
          } else {
            this.simpleLaunchModel.noCreditSign = 1
          }
        },
        immediate: true,
      },
      '$route.query': {
        handler(newValue) {
          if (newValue) {
            this.getQuery()
            this.getModelFromStore()
          }
        },
      },
    },
    computed: {
      /**
       * 是否为企业身份
       * @return {boolean}
       */
      isCom() {
        return !!this.userInfo.comId
      },
      userInfo() {
        return this.$store.getters.getUserInfo
      },
      // 是否已经存在发起方
      hasPartnerLauncher() {
        return this.simpleLaunchModel.partnerList.some(
          partner => partner.partnerRole === 0
        )
      },
      showSignOrder() {
        return this.signOrder ? '顺序签' : '无序签'
      },
      ...mapState({
        progressFile: state => state.launch.progressFile,
      }),
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.getQuery()
        this.getModelFromStore()
      },
      handleValiDay(num) {
        if (num * 1 > 365) {
          this.simpleLaunchModel.validDay = 365
        }
      },
      // 选择签署顺序popup
      handleShowOrderBubble() {
        this.showSignOrderBubble = !this.showSignOrderBubble
      },
      // 选择签署顺序
      handleSelectOrder(val) {
        this.signOrder = val
        this.handleShowOrderBubble()
        if (this.signOrder) {
          if (this.signOrderNumFlag === 1) return
          this.signOrderNumFlag = val
          this.$dialog
            .alert({
              title: '顺序签署提示',
              message: '您可以长按并拖动参与方卡片调换签署顺序',
            })
            .then(() => {
              // on close
            })
        }
      },
      handleShowTip() {
        this.showTip = !this.showTip
      },
      // 开始拖拽事件
      onStart() {
        this.drag = true
      },
      // 拖拽结束事件
      onEnd() {
        this.drag = false
        this.simpleLaunchModel.partnerList.forEach((item, index) => {
          item.defaultName = `参与方${index + 1}`
        })
      },
      handleClickUploadButton() {
        const { uploadInput } = this.$refs
        uploadInput.click()
      },
      async onUploadInputChange(e) {
        const { uploadInput } = this.$refs
        let loadingInstance

        try {
          // loadingInstance = this.$toast.loading({
          //   message: '上传中',
          //   forbidClick: false,
          //   duration: 20000,
          // })
          this.fileListloading = true
          const file = e.target.files[0]
          const randomKey = generateRandomKey(8, false)
          if (file) {
            const { name: fileName, size: fileSize } = file
            const { objectFormat, objectBase64, size } = await getFileData(file)
            if (size > 50 * 1024 * 1024) {
              this.$toast('文件大小不能超过50M')
              return
            }
            if (!objectFormat) {
              this.$toast(
                '只支持 .png .jpg .jpeg .doc .docx .xls .xlsx格式的文件'
              )
              uploadInput.value = ''
              return
            }
            const data = await uploadFile(
              {
                objectBase64,
                objectFormat,
              },
              file,
              randomKey
            )
            if (!data) {
              return
            }
            const { fileKey, fileUrl, imgList } = data
            const fileInfo = {
              fileIndex: this.simpleLaunchModel.fileList.length,
              fileName,
              fileSize,
              fileKey,
              fileUrl,
              imgList,
              pageNum: imgList.length,
            }
            uploadInput.value = ''
            this.simpleLaunchModel.fileList.unshift(fileInfo)
            const fileList = this.$store.getters['launch/getProgressFile']
            fileList.forEach((item, index) => {
              // eslint-disable-next-line eqeqeq
              if (item.randomKey == randomKey) {
                fileList.splice(index, 1)
              }
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.fileListloading = false
        }
      },
      getQuery() {
        this.key = this.$route.query?.key
        this.setFileListLoading()
      },
      getModelFromStore() {
        this.simpleLaunchModel =
          this.$store.getters['launch/simpleLaunchModel'][this.key] ||
          this.simpleLaunchModel
      },
      setFileListLoading() {
        if (this.key) {
          this.fileListloading = false
        } else {
          this.fileListloading = true
        }
      },
      storeData(value) {
        this.$store.commit('launch/setSimpleLaunchModel', {
          [this.key]: value,
        })
      },

      /**
       * 初始化参与方列表，将 发起方 添加到列表中
       * 需要区分 企业身份 和 个人身份
       */
      initPartnerList() {
        this.createPartnerLauncher()
      },

      /**
       * 创建一个参与方 外部企业
       */
      createPartnerCom() {
        const defaultModel = cloneDeep(this.partnerComModel)
        const partnerIndex = this.getPartnerIndex()
        const defaultName = `参与方${this.simpleLaunchModel.partnerList.length}`
        const launcherModel = {
          ...defaultModel,
          partnerIndex,
          defaultName,
          partnerSubList: [
            {
              // 文件索引
              fileIndex: '-1',
              // 文件key
              fileKey: null,
              // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
              operateList: [4],
              // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
              operateType: 4,
            },
          ],
        }
        this.simpleLaunchModel.partnerList.push(launcherModel)
      },

      /**
       * 创建一个参与方 外部个人
       */
      createPartnerPersonal() {
        const defaultModel = cloneDeep(this.partnerPersonalModel)
        const partnerIndex = this.getPartnerIndex()
        const defaultName = `参与方${this.simpleLaunchModel.partnerList.length}`
        const launcherModel = {
          ...defaultModel,
          partnerIndex,
          defaultName,
          partnerSubList: [
            {
              // 文件索引
              fileIndex: '-1',
              // 文件key
              fileKey: null,
              // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
              operateList: [1],
              // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
              operateType: 1,
            },
          ],
        }
        this.simpleLaunchModel.partnerList.push(launcherModel)
      },

      /**
       * 创建一个参与方 发起方
       */
      createPartnerLauncher() {
        const defaultModel = cloneDeep(this.partnerLauncherModel)
        let launcherModel = {}
        const partnerIndex = this.getPartnerIndex()
        // 如果是企业身份
        if (this.isCom) {
          //
          launcherModel = defaultModel
          launcherModel.partnerIndex = partnerIndex
        } else {
          // 个人身份
          launcherModel = {
            ...defaultModel,
            partnerIndex,
            uid: undefined,
            assignId: undefined,
            // 指定章
            assignType: undefined,
            partnerRole: 0,
            handlerTel: this.userInfo.telephone,
            handlerName: this.userInfo.name,
            partnerSubList: [
              {
                // 文件索引
                fileIndex: '-1',
                // 文件key
                fileKey: null,
                // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
                operateList: this.isCom ? [4] : [1],
                // 指定操作类型：0-个人签署 1-个人审批 2-经办人签署 3-法人签名 4-企业签章
                operateType: this.isCom ? 4 : 1,
              },
            ],
          }
        }
        console.log('内部发起方: ', launcherModel)
        launcherModel.defaultName = `参与方${this.simpleLaunchModel.partnerList.length}`
        this.simpleLaunchModel.partnerList.push(launcherModel)
      },
      getPartnerIndex() {
        return generateRandomKey(8, false)
      },
      onPartnerUpdate(index, model) {
        this.simpleLaunchModel.partnerList[index] = model

        this.storeData(this.simpleLaunchModel)
      },
      onDeleteFile(index) {
        this.simpleLaunchModel.fileList.splice(index, 1)
      },

      handleIncrement() {
        if (this.simpleLaunchModel.validDay < 365) {
          this.simpleLaunchModel.validDay += 1
        }
      },

      handleDecrement() {
        if (this.simpleLaunchModel.validDay > 1) {
          this.simpleLaunchModel.validDay -= 1
        }
      },

      onPartnerDelete(index) {
        this.simpleLaunchModel.partnerList.splice(index, 1)
      },

      onCreditSignChange(checked) {
        if (!checked) {
          this.handleShowWarnDialog()
          this.handleStartCount()
        } else {
          this.isCreditSign = true
        }
      },

      handleStartCount() {
        this.$nextTick(() => {
          const { countDown } = this.$refs
          countDown.start()
        })
      },

      handleResetCountDown() {
        this.$nextTick(() => {
          const { countDown } = this.$refs
          countDown.reset()
        })
      },

      onCountDownFinish() {
        this.isWarnDialogConfirmButtonEnabled = true
      },

      handleShowWarnDialog() {
        this.showWarnDialog = true
      },
      handleSetChecked() {
        this.isCreditSign = true
        this.handleCloseWarnDialog()
      },
      handleCloseWarnDialog() {
        this.showWarnDialog = false
        setTimeout(() => {
          this.handleResetCountDown()
          this.time = 3 * 1000
          this.isWarnDialogConfirmButtonEnabled = false
        }, 300)
      },

      onWarnDialogConfirm() {
        this.isCreditSign = false
        this.handleCloseWarnDialog()
      },

      /**
       * 是否包含财务权限
       */
      hasFinanceAuth() {
        return hasAuth(['finance_view'])
      },

      // 是否当前发起方位第一个参与方
      isLauncherFirst() {
        if (this.simpleLaunchModel.partnerList.length) {
          return (
            this.simpleLaunchModel.partnerList[0].partnerRole === 0 ||
            (this.hasPartnerLauncher && this.signOrder === 0)
          )
        }
      },
      // 判断是否有参与方待邀请
      // 待邀请参与方的条件是 外部组织或个人 没有填写手机号
      hasPartnerNeedInvite() {
        return this.simpleLaunchModel.partnerList.some(partner => {
          const { partnerRole, handlerTel } = partner
          // 如果是外部组织或者外部个人
          if (partnerRole === 1 || partnerRole === 2) {
            // 判断是否填写了手机号或者邮箱，如果没填写手机号或邮箱则返回true
            return !handlerTel
          }
          return false
        })
      },
      // 获取文件转图片的进度
      getFileUploadProgress(val) {
        try {
          const that = this
          if (!this.progressFile || this.progressFile.length === 0) return false
          const filePercentageStatus = this.progressFile.find(x => {
            return x.filePercentage !== 100
          })
          if (filePercentageStatus) {
            this.$toast('文件上传中，请稍后')
            return true
          }
          const fileUploadStatus = this.progressFile.find(x => {
            return !x.imgList
          })
          if (!filePercentageStatus && !fileUploadStatus) {
            return false
          }
          if (fileUploadStatus) {
            this.fileUploadLoading = true
            that.timer = setInterval(() => {
              const status = that.progressFile.find(x => {
                return !x.imgList
              })
              if (!status) {
                clearInterval(that.timer)
                if (val === 1) {
                  that.handleSave()
                } else {
                  that.handleSaveDraft()
                }
              }
            }, 1000)
          }
          return this.fileUploadLoading
        } catch (error) {
          console.error(error)
          this.fileUploadLoading = false
        }
      },
      // 直接发起
      async handleSave() {
        // if (this.fileListloading) {
        //   this.$toast('还有文件未上传成功，等待或删除再上传')
        //   return
        // }
        const progress = await this.getFileUploadProgress(1)
        if (progress) return
        try {
          this.$toast.loading({
            message: '处理中...',
            forbidClick: false,
            loadingType: 'spinner',
            duration: 20000,
          })

          this.simpleLaunchModel.fileList.forEach(item => {
            if(item.fileName){
              /** 配置所有emoji的正则 */
              let reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83E[\uDD00-\uDDFF]|\u2600-\u27BF]/g;
              const name = item.fileName.replace(reg, '');
              item.fileName = name
            }
          });

          const model = {
            ...this.simpleLaunchModel,
            launchSign: 1,
            step: SAVE_DRAFT_STEP.LAUNCH_FORM_PAGE,
            signOrderFlag: this.signOrder,
          }
          const res = await contractBusinessApi.simpleLaunch(model)
          // 余额不足
          if (res === 'no blance') {
            if (this.isCom) {
              // 企业身份 有财务权限
              if (this.hasFinanceAuth()) {
                await this.$dialog.alert({
                  title: '余额不足',
                  message: '您的账户剩余份数不足，暂不能发起，请先充值',
                  confirmButtonText: '去充值',
                  showCancelButton: '取消',
                })
                await this.$router.push({
                  name: 'account',
                  query: {
                    path: 'simplelaunch',
                    key: this.key,
                  },
                })
              } else {
                await this.$dialog.alert({
                  title: '余额不足',
                  message:
                    '您的企业账户剩余份数不足，暂不能发起，请联系管理员充值，建议您保存草稿',
                  confirmButtonText: '知道了',
                })
              }
            } else {
              await this.$dialog.alert({
                title: '余额不足',
                message: '您的账户剩余份数不足，暂不能发起，请先充值',
                confirmButtonText: '去充值',
                showCancelButton: true,
                cancelButtonText: '取消',
              })
              await this.$router.push({
                name: 'account',
                query: {
                  path: 'simplelaunch',
                  key: this.key,
                },
              })
            }
          } else {
            // 发起成功
            const { businessId } = res
            this.$toast.clear()

            if (businessId) {
              // 如果有待邀请的参与方
              if (this.hasPartnerNeedInvite()) {
                // 跳转至邀请页面
                this.$router.push({
                  name: 'InvitePartner',
                  query: {
                    isFromLaunch: 1,
                    isFirst: this.isLauncherFirst() ? 1 : 0,
                    businessId,
                  },
                })
                return
              }

              this.$router.push({
                name: 'LaunchSuccess',
                query: {
                  businessId,
                  isFirst: this.isLauncherFirst() ? 1 : 0,
                },
              })
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.$toast.clear()
        }
      },

      // 保存草稿
      async handleSaveDraft() {
        try {
          const progress = await this.getFileUploadProgress(2)
          if (progress) return
          this.$toast.loading({
            message: '处理中...',
            forbidClick: false,
            loadingType: 'spinner',
            duration: 20000,
          })
          this.simpleLaunchModel.fileList.forEach(item => {
            if(item.fileName){
              /** 配置所有emoji的正则 */
              let reg = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83E[\uDD00-\uDDFF]|\u2600-\u27BF]/g;
              const name = item.fileName.replace(reg, '');
              item.fileName = name
            }
          });
          const model = {
            ...this.simpleLaunchModel,
            launchSign: 0,
            signOrderFlag: this.signOrder,
            step: SAVE_DRAFT_STEP.LAUNCH_FORM_PAGE,
          }
          await contractBusinessApi.simpleLaunch(model)
          this.$toast.success('保存草稿成功')
        } catch (error) {
          console.error(error)
        } finally {
          this.$toast.clear()
        }
      },
      handleAllClose() {
        this.showTip = false
      },
    },
  }
</script>

<style lang="less" scoped>
  .simple-launch-page {
    background: #f4f5f6;
    padding-bottom: 70px;
    .loadingcContent {
      overflow: hidden;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url('../../assets/imgs/fileUploadLoadingBg.png');
      // background-size: 100% auto;
      /* 背景图垂直、水平均居中 */
      background-position: center center;
      /* 背景图不平铺 */
      background-repeat: no-repeat;
      /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
      background-attachment: fixed;
      /* 让背景图基于容器大小伸缩 */
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      .loadingProgress {
        width: 140px;
        height: 140px;
        line-height: 40px;
        background: #333;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        opacity: 0.8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        i {
          font-size: 16px;
        }
      }
    }
    .launch-item-container {
      background: #f4f5f6;
      .launch-item {
        padding: 16px;
        background: @WHITE;
        .item-title {
          font-size: 18px;
          font-weight: bold;
          color: #111a34;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right {
            display: flex;
            align-items: center;
            font-size: 14px;
            position: relative;
            img {
              width: 18px;
              height: 18px;
              margin-right: 6px;
            }
            .tip {
              width: 15px;
              height: 15px;
            }
            .order {
              font-size: 12px;
              color: #111a34;
              margin-right: 16px;
              position: relative;
              &::after {
                content: '';
                position: absolute;
                top: 6px;
                right: -12px;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                border-color: transparent;
                border-top: 5px solid #434751;
              }
            }
            .tip-content {
              position: absolute;
              background-color: rgba(0, 0, 0, 0.65);
              max-width: 290px;
              padding: 11px;
              color: @WHITE;
              font-size: 12px;
              border-radius: 2px;
              top: -63px;
              left: -218px;
              z-index: 99;
              &::before {
                position: absolute;
                content: '';
                top: 56px;
                left: 220px;
                border-top: 0 solid transparent;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid rgba(0, 0, 0, 0.65);
              }
            }
            .bubble-popup {
              position: absolute;
              max-width: 290px;
              z-index: 99;
              background-color: #fff;
              color: @WHITE;
              font-size: 15px;
              color: #111a34;
              width: 115px;
              height: 94px;
              top: 24px;
              left: -42px;
              border: 1px solid rgba(224, 224, 224, 1);
              border-radius: 4px;
              padding: 0;
              &::before {
                content: '';
                width: 0;
                height: 0;
                border: 5px solid;
                position: absolute;
                top: -10px;
                left: 75px;
                border-color: transparent transparent rgba(224, 224, 224, 1);
              }
              &::after {
                content: '';
                width: 0;
                height: 0;
                border: 5px solid;
                position: absolute;
                top: -9px;
                left: 75px;
                border-color: transparent transparent #fff;
              }
              p {
                height: 47px;
                line-height: 47px;
                text-align: center;
              }
              .order-active {
                background: #ebf3ff;
                font-weight: bold;
                color: #1676ff;
              }
            }
            .bubble-popup-before {
              &::after {
                border-color: transparent transparent #ebf3ff;
              }
            }
          }
        }
        .title-input {
          width: 100%;
          border: none;
          font-size: 14px;
          color: @TEXT-COLOR-0;
          padding: 14px 0;
          text-align: right;
        }

        .partner-list {
          margin-top: 12px;
          .partner-component {
            background: #fff;
          }
          .partner-component + .partner-component {
            margin-top: 16px;
          }
        }

        .partner-button-list {
          width: 100%;
          height: 100%;
          margin-top: 16px;
          padding: 12px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px dashed #1676ff;
          border-radius: 3px;
          p {
            width: 100%;
            height: 100%;
            font-size: 16px;
            color: #111a34;
            text-align: center;
          }
          .button-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
          }
          .partner-button {
            width: 93px;
            height: 37px;
            background: #ebf3ff;
            border-radius: 4px;
            border: 0;
            font-size: 14px;
            font-weight: bold;
            color: #1676ff;
            padding: 0;
            /deep/ .van-button__text {
              display: flex;
              align-items: center;
            }
            .add-icon {
              font-size: 16px;
            }
          }
          .partner-button + .partner-button {
            margin-left: 8px;
          }
        }

        .rule-list {
          display: flex;
          flex-direction: column;
          margin-top: 4px;
          .rule-item {
            height: 53px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eeeeee;
            padding: 2px 0;
            box-sizing: border-box;
            .rule-name {
              width: 140px;
              font-size: 14px;
            }
            .rule-value {
              display: flex;
              .valid-day {
                display: flex;
                align-content: center;
                .valid-day-value {
                  font-size: 14px;
                  width: 114px;
                  height: 30px;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: 1px solid #8a9ab4;
                  border-radius: 2px;
                  box-sizing: border-box;
                  .operation-icon {
                    font-size: 24px;
                    color: #8a9ab4;
                    width: 30px;
                    height: 100%;
                    display: inline-block;
                    text-align: center;
                    line-height: 23px;
                  }

                  .operation-value {
                    width: 53px;
                    height: 27px;
                    line-height: 27px;
                    display: inline-block;
                    text-align: center;
                    color: #111a34;
                    padding: 0;
                  }
                  .decrement {
                    border-right: 0.5px solid #8a9ab4;
                  }
                  .increment {
                    border-left: 0.5px solid #8a9ab4;
                  }
                }
              }

              .switch {
                /deep/ .van-checkbox__icon {
                  font-size: 18px;
                }
              }
              input {
                width: 270px;
              }
            }
          }
        }
      }
    }
    .launch-item-container + .launch-item-container {
      margin-top: 14px;
    }
    .launch-item-three {
      margin-top: 14px;
      margin-bottom: 40px;
    }
    .page-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 56px;
      background: @WHITE;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      z-index: 2;
      box-shadow: 0 -6px 10px 0 rgba(230, 234, 237, 0.3);

      .footer-button {
        width: 122px;
        height: 44px;
        border-radius: 4px;
        font-size: 16px;
        padding: 0;
      }
      .van-button--info {
        background: #ffffff;
        border: 1px solid #1676ff;
        border-radius: 4px;
        color: #1676ff;
      }
      .van-button--primary {
        background: #1676ff;
        color: #ffffff;
        margin-left: 12px;
      }
      .draft-button {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        font-size: 10px;
        color: #111a34;
        img {
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
        }
      }
    }
    .upload-file-input {
      display: none;
    }
  }
</style>

<style lang="less">
  .warn-dialog {
    border-radius: 6px;
    .main {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      .title {
        font-size: 20px;
        color: @TEXT-COLOR-0;
        text-align: center;
      }
      .content {
        margin-top: 24px;
        font-size: 16px;
        line-height: 24px;
        color: @TEXT-COLOR-1;
        text-align: center;
      }
    }
    .footer {
      display: flex;
      .warn-dialog-footer-button {
        flex: 1;
        height: 49px;
        border-radius: 0;
        font-size: 18px;
        &.button-cancel {
        }
        &.button-waiting {
          .van-button__text {
            display: flex;
          }
        }
        &.button-confirm {
          color: #1676ff;
        }
      }
      .warn-dialog-footer-button + .warn-dialog-footer-button {
        border-left: 0;
      }
    }
  }
</style>
