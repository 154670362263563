<template>
  <div class="partner">
    <div class="partner-header">
      <Tag text="发起方" text-color="#677283" background-color="#EEEEEE" />
      <span class="partner-title">参与方{{ index + 1 }}</span>
    </div>
    <img
      @click="handleDeleteCurPartner"
      class="delete-icon"
      src="@/assets/imgs/编组 17@2x.png"
      alt=""
    />
    <div class="partner-main">
      <!-- 企业 -->
      <template v-if="isCom">
        <div class="main-select" @click="handleShowSealOwnerPicker">
          <div class="left">
            <p v-if="userInfo.comName" class="title">{{ userInfo.comName }}</p>
            <p>
              <span>{{
                sealNameList[getIndexByAssignId()] || sealNameList[0]
              }}</span
              >,
              <span>{{
                curSealOwnerNameList[getCurSealOwnerIndexById(model.uid)] ||
                  '不指定盖章人'
              }}</span>
            </p>
          </div>
          <div class="right">
            <img src="@/assets/imgs/edit_outline@2x.svg" alt="" />
            编辑
          </div>
        </div>
      </template>
      <!-- 企业 -->
      <!-- 个人 -->
      <template v-else>
        <div class="main-select person">
          <div class="left">
            <p>
              <span v-if="model.handlerName">{{ model.handlerName }}</span>
              <span
                v-if="model.handlerName && model.handlerTel"
                style="font-size:14px"
              >
                •
              </span>
              <span>{{ model.handlerTel }}</span>
            </p>
          </div>
        </div>
      </template>
    </div>

    <!-- <van-popup v-model="showSealPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="sealNameList"
        @cancel="handleCloseSealPicker"
        @confirm="onShowSealPickerConfirm"
      />
    </van-popup> -->

    <van-popup
      v-model="showSealOwnerPicker"
      round
      position="bottom"
      append-to-body
      class="seal-picker"
    >
      <div class="popup-box">
        <div class="popup-header">
          <p class="title">指定发起方操作</p>
        </div>
        <van-picker
          show-toolbar
          :columns="curSealList"
          @cancel="handleCloseSealOwnerPicker"
          @confirm="onShowSealOwnerPickerConfirm"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
  import Tag from '@/components/Tag/Tag.vue'
  import sealApi from '@/api/seal'

  export default {
    name: 'PartnerLauncher',
    inject: ['onPartnerUpdate', 'onPartnerDelete'],
    components: {
      Tag,
    },
    props: {
      partnerModel: {
        type: Object,
        default: () => {},
        required: true,
      },
      index: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    data() {
      return {
        model: {
          assignId: undefined,
          uid: undefined,
          partnerRole: 0,
          handlerName: undefined,
          handlerTel: undefined,
        },

        // 签章list
        sealList: [],

        // 签章名字 list
        sealNameList: [],

        // 展示选择签章 picker
        showSealPicker: false,
        // 展示选择签章人picker
        showSealOwnerPicker: undefined,

        // 当前选择的签章的持有人 list
        curSealOwnerList: [],
        // 当前签章的签章持有人 name list
        curSealOwnerNameList: [],
        curSealList: [],
      }
    },

    watch: {
      'model.assignId': {
        handler(newValue) {
          this.curSealOwnerList = this.getCurSealOwnerList(newValue)
          this.model.uid = undefined
          this.getCurSealOwnerNameList()
        },
        immediate: true,
      },
      model: {
        handler(newValue) {
          this.onPartnerUpdate(this.index, newValue)
        },
        // immediate: true,
        deep: true,
      },
    },

    computed: {
      /**
       * 是否为企业身份
       */
      isCom() {
        return !!this.userInfo.comId
      },
      userInfo() {
        return this.$store.getters.getUserInfo
      },
    },
    created() {
      this.init()
      this.fetchData()
    },
    methods: {
      init() {
        this.model = {
          ...this.model,
          ...this.partnerModel,
        }
      },
      async fetchData() {
        if (this.isCom) {
          await this.getSealListByStatus()
        }
      },
      async getSealListByStatus() {
        try {
          this.sealList = await sealApi.getSealListByStatus()
          this.sealList.forEach(seal => {
            this.sealNameList.push(seal.sealName)
          })
          this.model.assignId = this.sealList[0].sealId
        } catch (error) {
          console.error(error)
        }
      },

      getCurSealOwnerNameList() {
        const curSealOwnerNameList = ['不指定盖章人']
        if (this.curSealOwnerList.length) {
          this.curSealOwnerList.forEach(sealOwner => {
            if (sealOwner.sealOwnerName != null) {
              curSealOwnerNameList.push(sealOwner.sealOwnerName)
            }
          })
        }
        this.curSealOwnerNameList = curSealOwnerNameList
      },

      handleShowSealPicker() {
        this.showSealPicker = true
      },
      handleCloseSealPicker() {
        this.showSealPicker = false
      },

      handleShowSealOwnerPicker() {
        this.curSealList = [
          { values: this.sealNameList },
          { values: this.curSealOwnerNameList },
        ]
        this.showSealOwnerPicker = true
      },
      handleCloseSealOwnerPicker() {
        this.showSealOwnerPicker = false
      },

      // 选择签章 picker 回调
      onShowSealPickerConfirm(value, index) {
        this.model.assignId = this.getAssignIdByIndex(index)
        this.handleCloseSealPicker()
      },

      // 选择签章持有人 picker 回调
      onShowSealOwnerPickerConfirm(value, index) {
        // 如果是 0 说明是选择的不指定盖章人
        if (index[1] === 0) {
          this.model.uid = undefined
        } else {
          this.model.uid = this.getCurSealUserIdByIndex(index[1] - 1)
        }
        this.model.assignId = this.getAssignIdByIndex(index[0])
        this.handleCloseSealOwnerPicker()
      },
      // 根据当前选中的签章的 id 找到当前签章在 list 中的 index
      getIndexByAssignId() {
        let curIndex
        const { assignId } = this.model
        this.sealList.forEach((seal, index) => {
          if (seal.sealId === assignId) {
            curIndex = index
          }
        })
        return curIndex
      },

      // 根据当前选中的签章索引 index 来找到它对应的 assignId
      getAssignIdByIndex(curSelectIndex) {
        let assignId
        this.sealList.forEach((seal, index) => {
          if (curSelectIndex === index) {
            assignId = seal.sealId
          }
        })
        return assignId
      },

      getCurSealOwnerList(sealId) {
        let curList = []
        this.sealList.forEach(seal => {
          if (seal.sealId === sealId) {
            curList = seal.sealOwnerList
          }
        })
        return curList
      },

      getCurSealUserIdByIndex(curIndex) {
        let id
        this.curSealOwnerList.forEach((sealOwner, index) => {
          if (index === curIndex) {
            id = sealOwner.sealUserId
          }
        })
        return id
      },

      getCurSealOwnerIndexById(sealUserId) {
        let curIndex
        this.curSealOwnerList.forEach((sealOwner, index) => {
          if (sealOwner.sealUserId === sealUserId) {
            curIndex = index
          }
        })
        return curIndex + 1
      },

      handleDeleteCurPartner() {
        this.onPartnerDelete(this.index)
      },

      // /**
      //  * 获取印章持有人 list
      //  * @return {Promise<void>}
      //  */
      // async getSealOwnerList(sealId) {
      //   try {
      //     const model = {
      //       sealId,
      //     }
      //     const { sealOwnerList } = await sealApi.getSealDetail(model)
      //   } catch (error) {
      //     console.error(error)
      //   }
      // },
    },
  }
</script>

<style lang="less" scoped>
  .partner {
    padding: 8px 16px 18px 16px;
    width: 343px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #f6f8fb;
    .partner-header {
      margin-left: -16px;
      display: flex;
      align-items: center;
      .partner-title {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 26px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        color: #677283;
        margin-left: 8px;
        font-size: 12px;
      }
    }
    .partner-main {
      .main-comName {
        max-width: 100%;
        font-size: 14px;
        color: @TEXT-COLOR-1;
        margin: 16px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .main-select {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        &.person {
          margin-top: 16px;
        }
        .select-item {
          flex: 1;
          border: 1px solid #e2e4ea;
          border-radius: 3px;
          box-sizing: border-box;
          .select-button {
            display: flex;
            justify-content: space-between;
            color: @TEXT-COLOR-0;
            font-size: 14px;
          }
          .item-value {
            height: 42px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .select-item + .select-item {
          margin-left: 8px;
        }
        .left {
          p {
            font-size: 14px;
            max-width: 176px;
            line-height: 20px;
            color: #111a34;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          span {
            font-size: 12px;
            color: #677283;
          }
          .title {
            margin-bottom: 6px;
          }
        }
        .right {
          display: flex;
          align-items: flex-end;
          font-size: 12px;
          color: #1676ff;
          img {
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }
      }
    }

    .delete-icon {
      position: absolute;
      font-size: 15px;
      top: -1px;
      right: -1px;
      width: 18px;
    }
  }
</style>
<style lang="less">
  .seal-picker {
    .popup-box {
      min-height: 322px;
      width: 100%;
    }
    .popup-header {
      width: 100%;
      height: 58px;
      background: #f4f5f6;
      border-radius: 7px 7px 0px 0px;
      line-height: 58px;
      padding: 0 16px;
      position: relative;
      .cancel {
        font-size: 14px;
        color: #666666;
        position: absolute;
        left: 16px;
        top: 0;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #111a34;
        text-align: center;
      }
    }
    .van-picker__columns {
      padding-bottom: 64px;
    }
    .van-picker__toolbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 13px 20px;
      position: absolute;
      bottom: 0;
      background: #ffffff;
      z-index: 9999;
      .van-picker__cancel {
        width: 164px;
        height: 44px;
        border: 1px solid #e2e4ea;
        border-radius: 4px;
        font-size: 16px;
        color: #111a34;
      }
      .van-picker__confirm {
        width: 164px;
        height: 44px;
        border-radius: 4px;
        font-size: 16px;
        background-color: #1676ff;
        color: #fff;
      }
    }
    .van-picker-column__item--selected {
      font-size: 16px;
      font-weight: bold;
      color: #1676ff;
    }
    .van-hairline-unset--top-bottom::after {
      border: 0 solid #1676ff;
      border-width: 1px 0;
    }
  }
</style>
