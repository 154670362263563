<template>
  <span class="tag" :style="styleObject">{{ text }}</span>
</template>

<script>
  export default {
    name: 'Tag',
    props: {
      text: {
        type: String,
        default: '',
      },
      textColor: {
        type: String,
        default: '#1676FF',
      },
      backgroundColor: {
        type: String,
        default: '#fff',
      },
      fontSize: {
        type: String,
        default: '12px',
      },
    },
    computed: {
      styleObject() {
        return {
          color: this.textColor,
          'background-color': this.backgroundColor,
          'font-size': this.fontSize,
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .tag {
    padding: 0 8px;
    height: 26px;
    line-height: 26px;
    font-weight: bold;
    border-radius: 0 4px 4px 0;
  }
</style>
