<template>
  <div class="select-file-progress">
    <van-progress :percentage="percentage" />
    <span class="count">{{ percentage }}%</span>
  </div>
</template>

<script>
  import { Progress, Icon } from 'vant'

  export default {
    name: 'SelectFileProgress',
    props: {
      value: {
        type: Number,
        default: () => 0,
      },
    },
    data() {
      return {
        percentage: 0,
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        setInterval(() => {
          if (this.percentage > 95) return
          this.percentage += 3
        }, 10)
      },
    },
  }
</script>
<style lang="less">
  .select-file-progress {
    flex: 1;
    font-size: 10px;
    display: flex;
    align-items: center;
    .van-progress__pivot {
      display: none;
    }
    .van-progress {
      flex: 1;
    }
    .count {
      margin-left: 10px;
    }
  }
</style>
