<template>
  <div class="partner">
    <div class="partner-header">
      <Tag text="外部个人" text-color="#ffffff" background-color="#8a9ab4" />
      <span class="partner-title">{{ model.defaultName }}</span>
      <div class="tip-container">
        <SvgIcon type="tip_circle" class="tip-icon" @click="handleShowTip" />
        <div v-if="showTip" class="tip-content">
          身份信息支持发起后补充/二维码邀请
        </div>
      </div>
    </div>
    <img
      @click="handleDeleteCurPartner"
      class="delete-icon"
      src="@/assets/imgs/编组 17@2x.png"
      alt=""
    />
    <div class="main-select" @click="handleShowSealOwnerPicker">
      <div class="left">
        <p class="title">
          <span v-if="!(model.handlerName || model.handlerTel)">
            先发起再邀请
          </span>
          <span v-if="model.handlerName">{{ model.handlerName }}</span>
          <span
            v-if="model.handlerName && model.handlerTel"
            style="font-size:14px"
          >
            •
          </span>
          <span>{{ model.handlerTel }}</span>
        </p>
      </div>
      <div class="right">
        <img src="@/assets/imgs/edit_outline@2x.svg" alt="" />
        编辑
      </div>
    </div>

    <!-- 企业身份选外部个人 -->
    <personOuter
      v-if="isCom"
      :show="showSelectPerson"
      @onClose="handleCloseSelectPerson"
      @roleCallback="onSelectConfirm"
      @comCallback="onSelectComConfirm"
      append-to-body
    />
    <!-- 企业身份选外部个人 -->

    <!-- 个人身份选外部个人 -->
    <personalFriend
      v-if="!isCom"
      :show="showSelectPerson"
      @onClose="handleCloseSelectPerson"
      @roleCallback="onSelectConfirm"
      append-to-body
    />
    <!-- 个人身份选外部个人 -->
    <van-popup v-model="showSealOwnerPicker" round position="bottom">
      <div class="popup-box">
        <div class="popup-header">
          <p class="title">指定参与方身份</p>
        </div>
        <div class="popup-main">
          <p class="select-people">
            <span class="left">手动填写</span>
            <span class="right" @click="handleShowSelectPerson"
              >通讯录选人 <img src="@/assets/imgs/next_outline.png" alt=""
            /></span>
          </p>
          <van-field
            v-model.trim="handlerName"
            label="经办人姓名"
            placeholder="非必填"
            input-align="right"
          />
          <van-field
            v-model.trim="handlerTel"
            label="经办人手机号/邮箱"
            label-class="label-phone"
            placeholder="非必填"
            input-align="right"
          />
          <van-field style="display: none;" />
        </div>
        <div class="popup-button">
          <van-button type="default" @click="handleCloseSealOwnerPicker"
            >取消</van-button
          >
          <van-button type="info" @click="popupConfirm">保存</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import Tag from '@/components/Tag/Tag.vue'
  import personalFriend from '@/components/selectPerson/personalFriend.vue'
  import personOuter from '@/components/selectPerson/personOuter.vue'

  export default {
    name: 'PartnerCom',
    inject: ['onPartnerUpdate', 'onPartnerDelete'],
    components: {
      Tag,
      personOuter,
      personalFriend,
    },
    props: {
      partnerModel: {
        type: Object,
        default: () => {},
        required: true,
      },
      index: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    data() {
      return {
        showTip: false,
        // 展示选人组件
        showSelectPerson: false,
        showSealOwnerPicker: false,
        handlerName: undefined,
        handlerTel: undefined,
        model: {
          assignId: undefined,
          defaultName: undefined,
          handlerName: undefined,
          handlerTel: undefined,
          partnerRole: 1,
        },
      }
    },
    computed: {
      isCom() {
        return !!this.$store.getters.getUserInfo.comId
      },
    },
    watch: {
      model: {
        handler(newValue) {
          this.onPartnerUpdate(this.index, newValue)
        },
        deep: true,
      },
      index: {
        handler(newValue) {
          this.model.defaultName = `参与方${newValue + 1}`
        },
        immediate: true,
      },
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.model = {
          ...this.partnerModel,
          ...this.model,
        }
      },
      handleShowTip() {
        this.showTip = !this.showTip
      },
      handleCloseTip(e) {
        const { nodeName } = e.target
        if (
          nodeName !== 'use' &&
          nodeName !== 'svg' &&
          ![...e.target.classList].includes('svg-icon')
        ) {
          if (e?.target?.className?.indexOf('tip-content') < 0) {
            if (this.showTip) {
              this.showTip = false
            }
          }
        }
      },

      handleShowSelectPerson() {
        this.showSelectPerson = true
      },

      handleCloseSelectPerson() {
        this.showSelectPerson = false
      },

      onSelectConfirm(value) {
        // console.log(value)
        const { uid, telephone, name, email } = value
        this.model.assignId = uid
        this.handlerTel = telephone || email
        this.handlerName = name
        this.handleCloseSelectPerson()
      },

      onSelectComConfirm(_, value) {
        const { name, email, telephone, uid } = value
        this.model.assignId = uid
        this.handlerTel = telephone || email
        this.handlerName = name
        this.handleCloseSelectPerson()
      },

      handleDeleteCurPartner() {
        this.onPartnerDelete(this.index)
      },
      handleShowSealOwnerPicker() {
        this.showSealOwnerPicker = true
      },
      handleCloseSealOwnerPicker() {
        this.showSealOwnerPicker = false
        if (this.model.handlerName || this.model.handlerTel) {
          this.handlerName = this.model.handlerName
          this.handlerTel = this.model.handlerTel
        } else {
          this.handlerName = undefined
          this.handlerTel = undefined
        }
      },
      // 选择签章持有人回调
      popupConfirm() {
        this.showSealOwnerPicker = false
        this.model.handlerName = this.handlerName
        this.model.handlerTel = this.handlerTel
      },
    },
    mounted() {
      document.addEventListener('click', this.handleCloseTip)
      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('click', this.handleCloseTip)
      })
    },
  }
</script>

<style lang="less" scoped>
  .partner {
    padding: 7px 16px 16px;
    width: 343px;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #f6f8fb;

    .partner-header {
      margin-left: -16px;
      display: flex;
      align-items: center;
      .partner-title {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 26px;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        color: #677283;
        margin-left: 8px;
        font-size: 12px;
      }
      .tip-container {
        display: flex;
        margin-left: 8px;
        align-items: center;
        position: relative;
        // width: 100%;
        .tip-icon {
          font-size: 14px;
        }
        .tip-content {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.65);
          max-width: 250px;
          padding: 11px;
          color: @WHITE;
          font-size: 12px;
          border-radius: 2px;
          white-space: nowrap;
          top: 26px;
          left: -50px;
          z-index: 99;
          &::before {
            position: absolute;
            content: '';
            top: -8px;
            left: 51px;
            border-top: 0 solid transparent;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
    .main-select {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      .left {
        p {
          font-size: 14px;
          max-width: 176px;
          line-height: 20px;
          color: #111a34;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .title {
          margin-bottom: 3px;
        }
      }
      .right {
        display: flex;
        align-items: flex-end;
        font-size: 12px;
        color: #1676ff;
        img {
          width: 15px;
          height: 15px;
          margin-right: 2px;
        }
      }
    }
    .delete-icon {
      position: absolute;
      font-size: 15px;
      top: -1px;
      right: -1px;
      width: 18px;
    }
    .popup-box {
      position: relative;
      display: flex;
      flex-direction: column;
      .popup-header {
        width: 100%;
        height: 58px;
        background: #f4f5f6;
        border-radius: 7px 7px 0px 0px;
        line-height: 58px;
        padding: 0 16px;
        position: relative;
        .cancel {
          font-size: 14px;
          color: #666666;
          position: absolute;
          left: 16px;
          top: 0;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #111a34;
          text-align: center;
        }
      }
      .popup-main {
        .select-people {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 22px;
          margin-bottom: 8px;
          padding: 0 16px;
          .left {
            font-size: 16px;
            font-weight: bold;
            color: #111a34;
          }
          .right {
            font-size: 16px;
            font-weight: bold;
            color: #1676ff;
            display: flex;
            align-items: center;
            img {
              width: 16px;
            }
          }
        }
        /deep/.label-phone {
          width: 119px;
        }
        .van-cell {
          height: 54px;
          line-height: 34px;
        }
      }
      .popup-button {
        display: flex;
        justify-content: space-around;
        padding: 25px 13px 16px;
        .van-button--default {
          width: 164px;
          height: 44px;
          border: 1px solid #e2e4ea;
          border-radius: 4px;
          font-size: 16px;
          color: #111a34;
        }
        .van-button--info {
          width: 164px;
          height: 44px;
          border-radius: 4px;
          font-size: 16px;
        }
      }
    }
  }
</style>
